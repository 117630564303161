



























































































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {getPageAPIs, Util} from "@/common/util";
import { excuteDownloadExport2Excel } from "@/common/xlsx";
import CardManagementAPI from "@/common/api/cardManagement";
export const pageApiList = getPageAPIs(CardManagementAPI);
@Component({})
export default class UserList extends Vue{
  constructor(){
    super()
    if(this.$store.state.platformType===2){
      this.merchantExcelList2ch['user_name'] = '用户名'
    }

    this.merchantExcelSortList = Object.keys(this.merchantExcelList2ch)
    if(this.$store.state.platformType===2){
      this.merchantExcelSortList.splice(1, 0, 'user_name')
    }

  }
  private buyAddressApi: any = new CardManagementAPI();
  private form: any = {
    time: [], // 时间选择
  };
  private visible: boolean = false;
  private forms: any = {
    phone: "",
    user_id: "",
    user_name:'',
  };
  private activated(): void {
    this.getTable();
  }
  private getTable(): void {
    this.buyAddressApi.card_management(
      {
        ...this.form,
        page: this.tablePage.pageNo,
        limit: this.tablePage.pageSize,
      },
      (res: any) => {
        this.tableData = res.data.list;
        this.tablePage.totalCount = res.data.count;
        this.tablePage.pageNo = res.data.page;
        this.tablePage.pageSize = res.data.prepage;
      }
    );
  }

  private onEdit(row:any):void{
    this.forms.user_id = row.user_id
    this.forms.phone = row.phone
    this.forms.user_name = row.user_name
    this.visible = true
  }

  private submit(): void {
    const { user_id ,user_name  } = this.forms
    const params = {
      user_id,
      user_name
    }
    this.buyAddressApi.editName(params, () => {
      this.visible = false;
      Util.showToast("编辑成功");
      this.search();
    });
  }

  /** 导出excel */
  private downloadExcelLoading: boolean = false;

  private merchantExcelList2ch: any = {
    phone: "手机号",
    money: "单店卡余额",
    spend: "消费",
    recharge: "总充值",
    lately_spend_time: "最后消费时间",
  };

  //Excel 表头的先后顺序
  private merchantExcelSortList: string[] = []

  // 获取excelJSON数据
  private getExcelJSONDataByList(list: any[], sortKey: string[], map2ch: any) {
    const newList = list.map((eachItem) => {
      const newItem: any = {};

      for (const key of sortKey) {
        const val = eachItem[key];

        const chKey = map2ch[key];
        if (val) {
          newItem[chKey] = val;
        } else {
          newItem[chKey] = "";
        }
      }

      return newItem;
    });

    return newList;
  }

  // 点击导出excel 表格
  private handleExportExcelChannelList() {
    const form = this.form;
    const { time, channelId } = form;

    const params: any = {
      channelId,
    };

    if (Array.isArray(time) && time.length === 2) {
      params.startDate = Util.dateTimeUtilnohms(this.form.time[0]);
      params.endDate = Util.dateTimeUtilnohms(this.form.time[1]);
    } else {
      this.$message.error("请选择时段");

      return;
    }

    if (params === null) {
      return false;
    }

    this.downloadExcelLoading = true;
    this.buyAddressApi.export_card_management(
      { ...this.form },
      (res: any) => {
        const start_date: string = Util.dateUtil(this.form.time[0]);
        const end_date: string = Util.dateUtil(this.form.time[1]);
        const filename = `单店卡用户管理 ${start_date}到${end_date}.xlsx`;
        // const { list: originList } = res.data || {};
        const originList = res.data.list || [];

        const newList = Array.isArray(originList)
          ? originList.map((item) => {
              // const { orderNum } = item || {};
              const newItem = {
                ...(item || {}),
              };

              for (let key in item) {
                if (typeof item[key] == "number") {
                  newItem[key] = item[key].toString();
                }
              }

              return newItem;
            })
          : [];

        if (newList.length > 0) {
          const merchantExcelSortList = this.merchantExcelSortList;

          const merchantExcelList2ch = this.merchantExcelList2ch;

          const excelJSONData = this.getExcelJSONDataByList(
            newList,
            merchantExcelSortList,
            merchantExcelList2ch
          );

          excuteDownloadExport2Excel(excelJSONData, filename);
        } else {
          this.$message.info("选中时段没有可导出的信息");
        }


        this.downloadExcelLoading = false;
      },
      () => {
        this.downloadExcelLoading = false;
      }
    );
  }

  /** end 导出excel */
  private restForm(): void {
    this.form = new Util().clearObject(this.form);

    this.getTable();
  }

  private search(): void {
    this.tablePage.pageNo = 1;
    this.tableData = [];
    this.tablePage.totalCount = 0;

    this.getTable();
  }

  private tableData: any[] = [];

  private tablePage: any = {
    totalCount: 0,
    pageNo: 1,
    pageSize: 10,
    pageSizes: [10, 20, 50, 100, 200, 500],
  };

  private handlePageChange(val: any) {
    this.tablePage.pageNo = val.currentPage;
    this.tablePage.pageSize = val.pageSize;

    this.getTable();
  }
}
